/* eslint-disable  react/prop-types */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Heading from '../../app-components/dashed-heading'
import OfferDropdown from '../offerDropdown'
import Button from '../../app-components/button'
import './styles.scss'

const Conditions = ({ title, subtitle, description, sections, link }) => (
  <Container className="conditions">
    <Row className="justify-content-center">
      <Heading align="left" title={title} />
      <Col xs="12" md="12" lg="12" className="top-section text-left">
        {subtitle !== null ? <h3 className="">{subtitle}</h3> : null}
        {description !== null ? <p className="">{description}</p> : null}
        <OfferDropdown sections={sections} />
        {link !== null ? (
          <Button className="text-center" name={link.title} href={link.url} />
        ) : null}
      </Col>
    </Row>
  </Container>
)

export default Conditions
