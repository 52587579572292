/* eslint-disable  react/prop-types */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import './styles.scss'

const BlockItem = ({ imageFile, cta: { url, title }, description }) => {
  return (
    <Col className="item-block" xs="12" md="6">
      <img alt="" src={imageFile?.sourceUrl} />
      <div className="desc">{description}</div>
      {url && (
        <div className="cta">
          <Link to={url} className="btn btn-custom">
            {title}
          </Link>
        </div>
      )}
    </Col>
  )
}
const LeftRightBlocks = ({ blocks }) => (
  <Container className="left-right-blocks">
    <Row>
      <Col xs={{ span: 12, offset: 0 }} md={{ span: 8, offset: 2 }}>
        <Row>{blocks.map(BlockItem)}</Row>
      </Col>
    </Row>
  </Container>
)

export default LeftRightBlocks
