/* eslint-disable react/destructuring-assignment, react/prop-types */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import Heading from '../../../app-components/dashed-heading'

import './styles.scss'

const LandingBanner = ({ title, description, button, color, imageFile }) => (
  <div
    className="landing-banner justify-content-center"
    style={
      imageFile
        ? { backgroundImage: `url(${imageFile.sourceUrl})` }
        : { backgroundColor: color }
    }
  >
    <Container className="banner-text">
      <Row className="justify-content-center">
        <Col xs="12" md="8" lg="6">
          <Heading title={title} description={description} button={button} />
        </Col>
      </Row>
    </Container>
  </div>
)

export default LandingBanner
