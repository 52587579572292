/* eslint-disable  react/prop-types */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import Heading from '../../app-components/dashed-heading'
import './styles.scss'

const FullWidthMedia = ({
  className,
  title,
  description,
  button,
  mediaType,
  imageFile,
  videoFile,
}) => (
  <div className="full-width">
    <Container className="text-head">
      <Row className="justify-content-center">
        <Col xs="12" md="8" lg="6" className="top-section text-center mt-4">
          <Heading
            className={className}
            title={title}
            description={description}
            button={button}
          />
        </Col>
      </Row>
    </Container>
    <div className="content-media">
      {mediaType === 'image' && imageFile.sourceUrl ? (
        <img src={imageFile.sourceUrl} alt={title} />
      ) : (
        <div>{videoFile}</div>
      )}
    </div>
  </div>
)

export default FullWidthMedia
