/* eslint-disable  array-callback-return, consistent-return, react/no-unescaped-entities */
import React from 'react'

import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import Layout from '../layouts/default'
import Clients from '../components/sections/clients'
import SimpleText from '../components/sections/simpleText'
import Achievements from '../components/sections/achievements'
import Testimonials from '../components/sections/testimonials'
import Services from '../components/sections/services'
import Partners from '../components/sections/partners'
import CaseStudies from '../components/sections/caseStudies'
import Banner from '../components/sections/_banner/default'
import LeftRightBlocks from '../components/sections/leftRightBlocks'
import Gallery from '../components/sections/gallery'
import FullText from '../components/sections/fullText'
import FullWidthMedia from '../components/sections/fullWidthMedia'
import LandingBanner from '../components/sections/_banner/landing'
import HomeBanner from '../components/sections/_banner/home'
import Conditions from '../components/sections/conditions'

const DefaultTemplate = ({
  data: {
    wpPage: {
      template: { acf },
      seo,
    },
  },
}) => {
  const getHeader = (acf) => {
    const { headerType, headerTitle, headerBackgroundColor, headerImageFile } =
      acf
    if (headerType === 'header_2_picture') {
      return <HomeBanner data={acf} />
    }
    return (
      <Banner
        img="default"
        title={headerTitle}
        backgroundImg={headerImageFile?.sourceUrl ?? ''}
        color={headerBackgroundColor}
      />
    )
  }

  return (
    <Layout seo={seo}>
      {acf && getHeader(acf)}
      {acf?.blocks.map((item) => {
        if (!item?.__typename) return false
        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_Clients' &&
          item.clients
        )
          return <Clients clients={item.clients} />

        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_SimpleText' &&
          item.simpleTextTitle &&
          item.simpleTextDescription
        )
          return (
            <SimpleText
              className="heading"
              title={item.simpleTextTitle}
              description={item.simpleTextDescription}
              button={item.simpleTextCta}
            />
          )
        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_Services' &&
          item.services
        )
          return <Services services={item.services} />

        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_Gallery' &&
          item.gallery
        )
          return <Gallery galleries={item.gallery} />

        if (item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_FullText')
          return (
            <FullText
              title={item.fullTextTitle}
              description={item.fullTextDescription}
            />
          )

        if (item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_FullWidth')
          return (
            <FullWidthMedia
              title={item.fullWidthTitle}
              description={item.fullWidthDescription}
              button={item.fullWidthCta}
              media_type={item.fullWidthMediaType}
              image_file={item.fullWidthImageFile}
              video_file={item.fullWidthVideoFile}
            />
          )

        if (
          item.__typename ===
            'WpTemplate_LandingPage_Acf_Blocks_Realizations' &&
          item.realizations
        )
          return <Achievements achievements={item.realizations} />

        if (
          item.__typename ===
            'WpTemplate_LandingPage_Acf_Blocks_Testimonials' &&
          item.testimonials
        )
          return (
            <Testimonials
              testimonials={item.testimonials}
              title={item.testimonialsBlockTitle}
            />
          )

        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_LeftRightBlock'
        )
          return <Partners data={item} />

        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_Banner' &&
          item.bannerTitle
        )
          return (
            <LandingBanner
              title={item.bannerTitle}
              description={item.bannerDescription}
              button={item.bannerCta}
              color={item.bannerColor}
              image_file={item.bannerImageFile}
            />
          )

        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_UseCase' &&
          item.useCase
        )
          return <CaseStudies cases={item.useCase} />

        if (
          item.__typename ===
            'WpTemplate_LandingPage_Acf_Blocks_LeftRightBlocks' &&
          item.leftRightBlocks
        )
          return <LeftRightBlocks blocks={item.leftRightBlocks} />

        if (
          item.__typename === 'WpTemplate_LandingPage_Acf_Blocks_Cgv' &&
          item.cgvTitle
        )
          return (
            <Conditions
              title={item.cgvTitle}
              subtitle={item.cgvSubtitle}
              description={item.cgvSubtitleContent}
              sections={item.cgvAccordion}
              link={item.cgvLink}
            />
          )

        return false
      })}
    </Layout>
  )
}

DefaultTemplate.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_LandingPage {
          acf {
            blocks {
              ... on WpTemplate_LandingPage_Acf_Blocks_SimpleText {
                __typename
                simpleTextDescription
                simpleTextTitle
                simpleTextCta {
                  url
                  title
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_LeftRightBlock {
                __typename
                leftRightBlockDescription
                leftRightBlockImageFormat
                leftRightBlockMediaType
                leftRightBlockTitle
                leftRightBlockCta {
                  title
                  url
                }
                leftRightBlockImageFile {
                  sourceUrl
                }
                leftRightBlockVideoFile {
                  sourceUrl
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_Services {
                __typename
                services {
                  ...serviceFragment
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_Realizations {
                __typename
                realizations {
                  ...realizationFragment
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_Clients {
                __typename
                clients {
                  ...clientFragment
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_Testimonials {
                __typename
                testimonialsBlockTitle
                testimonials {
                  ...testimonialFragment
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_FullWidth {
                __typename
                fullWidthDescription
                fullWidthMediaType
                fullWidthTitle
                fullWidthVideoFile {
                  sourceUrl
                }
                fullWidthImageFile {
                  sourceUrl
                }
                fullWidthCta {
                  url
                  title
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_Banner {
                __typename
                bannerColor
                bannerDescription
                bannerTitle
                bannerImageFile {
                  sourceUrl
                  title
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_UseCase {
                __typename
                useCase {
                  imageFile {
                    sourceUrl
                  }
                  mediaType
                  title
                  url
                  videoFile {
                    sourceUrl
                  }
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_FullText {
                __typename
                fullTextDescription
                fullTextTitle
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_Gallery {
                __typename
                gallery {
                  grid
                  hideMobile
                  mediaType
                  videoFile {
                    sourceUrl
                  }
                  imageFile {
                    sourceUrl
                  }
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_LeftRightBlocks {
                __typename
                leftRightBlocks {
                  cta {
                    title
                    url
                  }
                  description
                  imageFile {
                    sourceUrl
                  }
                }
              }
              ... on WpTemplate_LandingPage_Acf_Blocks_Cgv {
                __typename
                cgvSubtitle
                cgvSubtitleContent
                cgvTitle
                cgvLink {
                  url
                  title
                }
                cgvAccordion {
                  title
                  description
                }
              }
            }
            headerBackgroundColor
            headerDescription
            headerImageFile {
              sourceUrl
            }
            headerImageLeft {
              sourceUrl
            }
            headerImageMobile {
              sourceUrl
            }
            headerImageRight {
              sourceUrl
            }
            headerPrimaryButton {
              title
              url
            }
            headerSecondaryButton {
              url
              title
            }
            headerTitle
            headerType
          }
          templateName
        }
      }
      ...yoastMeta
    }
  }
`
export default DefaultTemplate
