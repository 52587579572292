/* eslint-disable  react/prop-types */
import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.scss'

import Heading from '../../app-components/dashed-heading'

const FullText = ({ title, description }) => (
  <Container className="full-text">
    {title && <Heading className="sm-heading" align="left" title={title} />}
    <div
      className="description"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </Container>
)

export default FullText
